





























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import EventSearchResult from '@/components/search-results/EventSearchResult.vue'
import ContactBox from '@/components/hszg/ContactBox.vue'
import CalendarContainer from '@/components/forms/CalendarContainer.vue'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'

@Component({
  components: { CalendarContainer, EventSearchResult, ContactBox }
})
export default class EventsOverview extends Vue {
  get writeable (): boolean {
    return hasRight(Rights.CREATE_EVENT)
  }

  get readonly (): boolean {
    return hasRight(Rights.CREATE_EVENT)
  }

  get bookmarkable (): boolean {
    return hasRight(Rights.BOOKMARK_EVENT)
  }

  get manage (): boolean {
    return hasRight(Rights.MANAGE_EVENT)
  }
}
